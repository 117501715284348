import * as React from 'react';
import PageSeo from '../../components/Seo/PageSeo';
import styled from 'styled-components';
import PageSection from '../../components/PageSection';
import { HeroBanner2, TwoColumnLayout, GradientCard, CalloutBanner, PageSeparator, TextBlock } from '../../components';
import PhillIcon from '../../assets/images/phill_icon.svg';
import CogwheelsIcon from '../../assets/images/icon-cogwheels.svg';
import  KidneyIcon from '../../assets/images/icon-kidneys-2.svg';

// SEO INFORMATION
const pageMeta = {
  title: 'Advanced Renal Cell Carcinoma Treatment',
  keywords: 'welireg for advanced renal cell carcinoma, belzutifan for advanced renal cell carcinoma',
  description:  'Health care professionals may find information about a treatment option for advanced renal cell carcinoma (RCC). Explore here.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/","@type":"ApprovedIndication","name":"WELIREG™","alternateName":"belzutifan","description":"WELIREG is indicated for the treatment of adult patients with advanced renal cell carcinoma (RCC) following a programmed death receptor-1 (PD-1) or programmed death-ligand 1 (PD-L1) inhibitor and a vascular endothelial growth factor tyrosine kinase inhibitor (VEGF-TKI)."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/","@type":"Drug","proprietaryName":"WELIREG™","nonProprietaryName":"belzutifan","manufacturer":"Merck"}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/","@type":"MedicalEntity","guideline":"Belzutifan (WELIREG) is the only HIF⁠-⁠2α inhibitor included as an other recommended subsequent therapy option (NCCN Category 2A) or patients with advanced clear cell RCC following prior PD⁠-⁠1 or PD⁠-⁠L1 inhibitor and VEGF⁠-⁠TKI therapies","recognizingAuthority":"NCCN® = National Comprehensive Cancer Network® "}`
  ]
}

const MOATextBlock = styled(TextBlock)`
  b{
    font-weight: 600;
  }
  i {
    font-style: italic;
  }
`;

const StandardSection = styled(PageSection)`
  display: flex;
  justify-content: center;
  padding: 0;
`;

const cardsData = [
  {
    icon: CogwheelsIcon,
    title: 'How WELIREG Works',
    description: <>The approval of WELIREG marks the first treatment option in a novel therapeutic class available for your appropriate patients with advanced RCC since 2015.<sup>1</sup></>,
    linkText: 'Learn More',
    linkUrl: '/advanced-renal-cell-carcinoma/mechanism-of-action',
  },
  {
    icon: PhillIcon,
    title: 'Dispensing & Ordering WELIREG',
    description: 'Get information on how WELIREG prescriptions can be filled.',
    linkText: 'View Details',
    linkUrl: '/advanced-renal-cell-carcinoma/resources-and-access',
  },
];

const calloutBanner = {
  title: <>WELIREG may be considered as early as the second line for your appropriate patients with advanced RCC following treatment with both anti&#8288;–&#8288;PD&#8288;-&#8288;1&#8288;/&#8288;L1 and VEGF&#8288;-&#8288;TKI therapies</>,
  externalInvertedButtons: true,
  links: [
    {
      label: 'Connect With a Merck Associate',
      url: 'https://www.askmerck.com/',
    },
  ],
};

const eyeBrow = <>FOLLOWING TREATMENT WITH BOTH ANTI&#8288;–&#8288;PD&#8288;-&#8288;1&#8288;/&#8288;L1 and VEGF&#8288;-&#8288;TKI* THERAPIES,</>;
const heading1 = <>Reimagine<br/>your approach</>;
const heading2 = <>For adults with advanced RCC</>;
const description = <>WELIREG is indicated for the treatment of adult patients with advanced renal cell carcinoma (RCC) following a programmed death receptor-1 (PD&#8288;-&#8288;1) or programmed death-ligand 1 (PD&#8288;-&#8288;L1) inhibitor and a vascular endothelial growth factor tyrosine kinase inhibitor (VEGF&#8288;-&#8288;TKI).</>;
const bannerText = <>DISCOVER <b>WELIREG</b>, THE FIRST AND ONLY HIF-2α INHIBITOR FOR YOUR APPROPRIATE PATIENTS WITH ADVANCED RCC</>;
const footnotes = [
  <><sup>*</sup>PD&#8288;-&#8288;1/L1 = programmed death receptor-1 (PD&#8288;-&#8288;1)/programmed death-ligand 1 (PD&#8288;-&#8288;L1); VEGF&#8288;-&#8288;TKI = vascular endothelial growth factor tyrosine kinase inhibitor.</>,
  'HIF-2α = hypoxia-inducible factor 2 alpha.',
];

const cardNCCN=   {
  icon: KidneyIcon,
  title: 'National Comprehensive Cancer Network® (NCCN®) Recommendation',
  description: <>Belzutifan (WELIREG) is the only <strong>HIF&#8288;-&#8288;2α inhibitor included as an other recommended subsequent therapy option (NCCN Category 2A)</strong> for patients with advanced clear cell RCC following prior PD&#8288;-&#8288;1 or PD&#8288;-&#8288;L1 inhibitor and VEGF&#8288;-&#8288;TKI therapies.<sup>2,a</sup></>,
  linkText: 'Learn More',
  linkUrl: '/advanced-renal-cell-carcinoma/efficacy/#Guidelines',
};

const IndexPage = () => {
  
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/advanced-renal-cell-carcinoma/" />
      </PageSeo>

        <HeroBanner2 linkUrl='/advanced-renal-cell-carcinoma/efficacy' linkText='Explore Efficacy'
          eyeBrow={eyeBrow}
          heading1={heading1}
          heading2={heading2}
          description={description}
          bannerText={bannerText}
          footnotes={footnotes}
        />

      <PageSeparator topPadding='18px' topPaddingMobile='30px' />

      <StandardSection>
        <TwoColumnLayout>
          {
            cardsData.map((card, index) => (
              <GradientCard key={index} icon={card.icon} title={card.title} description={card.description} linkText={card.linkText} linkUrl={card.linkUrl} />
            ))
          }
        </TwoColumnLayout>
      </StandardSection>

      <PageSeparator topPadding='24px' topPaddingMobile='20px' />

      <StandardSection>
        <GradientCard icon={cardNCCN.icon} title={cardNCCN.title} description={cardNCCN.description} linkText={cardNCCN.linkText} linkUrl={cardNCCN.linkUrl} />
      </StandardSection>

      <StandardSection>
          <MOATextBlock
            footer={{ definitions: <>
              <sup className='footnote-super'>a</sup>This regimen is for patients that have received a PD&#8288;-&#8288;1 or PD&#8288;-&#8288;L1 inhibitor and a VEGF&#8288;-&#8288;TKI. <br/>
              Category 2A = Based on lower-level evidence, there is uniform NCCN consensus that the intervention is appropriate.<br/>
              NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.<br/><br/>
              NCCN = National Comprehensive Cancer Network® (NCCN®).<br/><br/>
              <b>References: 1.</b> Yap NY, Khoo WT, Perumal K, et al. Practical updates in medical therapy for advanced and metastatic renal cell carcinoma. <i>Urol Sci.</i> 2018;29(3):120–128. <b>2.</b> Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer V.2.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed January 5, 2024. To view the most recent and complete version of the guideline, go online to NCCN.org.
              </>}} >
          </MOATextBlock>
      </StandardSection>
      <PageSeparator topPadding='24px' topPaddingMobile='30px' />


      <CalloutBanner {...calloutBanner} />

    </>
  );
};

export default IndexPage;
